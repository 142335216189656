import React, { FC, ReactNode } from 'react';
import Head from 'next/head';
import styled from '@emotion/styled';
import shallow from 'zustand/shallow';
import { AnimatePresence, motion } from 'framer-motion';
import { IBreadCrumb, IMetaHtmlHeadModel, INavigation } from '$models';
import { MainNavigation } from '~/modules/main-navigation';
import { Container } from '..';
import { useSite } from '~/store/site';
import { HEADER_HEIGHT_LG, HEADER_HEIGHT_SM } from '$constants';
import { mq } from '$lib/helpers';
import { AppContentContainer } from '~/modules/app';
import { useAppStore } from '~/store';
import { CheckoutNavigation } from '$templates/checkout-page/checkout-navigation';
import { PageTransitionContent } from '$components/elements/page-transition';
import { Breadcrumbs } from '$components/elements/breadcrumbs';
import { Footer } from '$components/elements/footer';
import { StructuredData } from '$components/elements/structured-data';
import { convertStructuredBreadcrumb } from '$lib/helpers/structured-data.helper';
import { StructuredDataKey } from '$constants/structured-data';

type Props = {
    children?: ReactNode;
    title?: string;
    navigation?: INavigation;
    metaData?: IMetaHtmlHeadModel;
    breadcrumbs?: IBreadCrumb[];
    isCheckout?: boolean;
    appBasketInfo?: ReactNode;
};

export const Layout: FC<Props> = ({
    children,
    metaData,
    title = 'This is the default title',
    navigation: menu,
    breadcrumbs,
    isCheckout = false,
    appBasketInfo,
}: Props): JSX.Element => {
    const { backdrop } = useSite();
    const { isApp, appTopPadding } = useAppStore(
        (state) => ({ isApp: state.isApp, appTopPadding: state.appTopPadding }),
        shallow
    );

    return (
        <>
            <Head>
                <title>{metaData?.browserTitle || title}</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                {!!metaData?.alternateLanguages &&
                    Object.keys(metaData.alternateLanguages).map((x) => (
                        // eslint-disable-next-line react/no-unknown-property
                        <link rel="alternate" key={x} href={metaData.alternateLanguages[x]} hrefLang={x} />
                    ))}

                {!!metaData?.canonical && <link rel="canonical" href={metaData.canonical} />}
                {!!metaData?.relLinks?.length &&
                    metaData.relLinks.map((x) => <link key={x.type} rel={x.type} href={x.link} />)}

                {!!metaData?.metaHttpEquivData?.['content-Language'] && (
                    // eslint-disable-next-line react/no-unknown-property
                    <meta httpEquiv="Content-Language" content={metaData.metaHttpEquivData['content-Language']} />
                )}
                {!!metaData?.metaHttpEquivData?.['content-Type'] && (
                    // eslint-disable-next-line react/no-unknown-property
                    <meta httpEquiv="Content-Type" content={metaData?.metaHttpEquivData?.['content-Type']} />
                )}
                {!!metaData?.metaData?.length &&
                    metaData.metaData.map((x) => <meta key={x.key} name={x.key} content={x.value} />)}
                {!!metaData?.metaPropertyData?.length &&
                    metaData.metaPropertyData.map((x) => <meta key={x.key} property={x.key} content={x.value} />)}
            </Head>
            {breadcrumbs && (
                <StructuredData
                    structuredDataKey={StructuredDataKey.BreadCrumb}
                    jsonData={convertStructuredBreadcrumb(metaData?.browserTitle || title, breadcrumbs)}
                />
            )}
            {isApp && (
                <Main noTopSpacing>
                    <AppPaddingContainer basket={!!appBasketInfo} appTopPadding={appTopPadding}>
                        {children}
                        {appBasketInfo}
                    </AppPaddingContainer>
                </Main>
            )}
            <AppContentContainer>
                {isCheckout ? (
                    <>
                        <CheckoutNavigation />
                        <Main noTopSpacing>{children}</Main>
                    </>
                ) : (
                    <>
                        {menu && <MainNavigation menu={menu} />}

                        <Main>
                            <PageTransitionContent>
                                <Container>
                                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                                </Container>
                                {children}
                            </PageTransitionContent>
                        </Main>
                    </>
                )}

                <Footer columns={menu?.footerItems} paymentMethods={menu?.paymentMethods} isCheckout={isCheckout} />
            </AppContentContainer>

            <AnimatePresence>
                {backdrop && (
                    <Backdrop
                        key="backdrop"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.5 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: 'easeOut', duration: 0.3 }}
                    />
                )}
            </AnimatePresence>
        </>
    );
};

const Main = styled.main<{ noTopSpacing?: boolean }>(({ theme, noTopSpacing }) => ({
    marginTop: noTopSpacing ? 0 : `${HEADER_HEIGHT_SM}px`,
    minHeight: `calc(100vh - ${HEADER_HEIGHT_SM}px)`,
    paddingBottom: theme.space[8],
    position: 'relative',

    [mq('frame')]: {
        marginTop: noTopSpacing ? 0 : `${HEADER_HEIGHT_LG}px`,
        minHeight: `calc(100vh - ${HEADER_HEIGHT_LG}px)`,
    },
}));

const Backdrop = styled(motion.div)(({ theme }) => ({
    position: 'fixed',
    backgroundColor: theme.colors.black,
    opacity: '50%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndices.backdrop,
}));

const AppPaddingContainer = styled.div<{ basket?: boolean; appTopPadding?: number }>(({ basket, appTopPadding }) => ({
    paddingTop: appTopPadding ? appTopPadding : 0,
    paddingBottom: basket ? 185 : 80, // Tab bar height and fixed app basket info height
}));
